import React, {useState} from "react"
import { graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss"; 
import "../scss/custom.scss";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import NewsDetail from "../components/Home/News/NewsDetail";

const NewsTemplate = ({data}) => {
    const GQLPage = data.glstrapi?.newsBlog;
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
  

    const handlerClick = () => {
        var root = document.getElementsByTagName('html')[0];
        setShowMenu(!showMenu)
        if (showMenu == true) {
            document.body.className = '';
            root.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
            root.classList.add("overflow-hidden");
        }
    }

    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        setShowMenu(false)
        if (showSearch == true) {
            document.body.className = '';

        } else {
            document.body.classList.add("overflow-hidden");
        }
    }

    return (
        <div className={` ${(showMenu || showSearch) ? "wrapper video-page open-search-block" : "wrapper video-page"}`} > 
             <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} /> 
             <NewsDetail news={GQLPage}  />
            <Footer popularSearch={'Popular_Search_Home'} popularSearchType="static" />
        </div>
    )
}

export default NewsTemplate;

export const pageQuery = graphql`
    query NewsQuery($id: ID!) {
        glstrapi {
            newsBlog(id: $id, publicationState: LIVE) {
                id
                URL
                Title
                Meta_Title
                Meta_Description
                Short_Description
                Publish
                AutoPlay
                Repeat
                News_Content {
                ... on GLSTRAPI_ComponentModulesContentWithMultipleImages {
                    id
                    Content
                    __typename
                    Images {
                    url
                    alternativeText
                    }
                    Title
                }
                ... on GLSTRAPI_ComponentComponentsAddServiceContent {
                    CTA_Label
                    __typename
                    CTA_Link {
                    id
                    }
                    id
                    Description
                    ServiceTitle: Title
                } 
                ... on GLSTRAPI_ComponentModulesIntro {
                    id
                    __typename
                    Intro_Content
                    Intro_Title
                }
                ... on GLSTRAPI_ComponentModulesContentWithCollection {
                    id
                    __typename
                    CTA_Label
                    CTA_Link {
                    id
                    }
                    Title
                    Sub_Title
                } 
                }
                Meta_Title
                Meta_Description
                Embed_Video_URL
                Date
                Banner_Image {
                url
                alternativeText
                formats
                }
                Tile_Image {
                url
                alternativeText
                }
            }
        }
    }
`